import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Cabin } from "./models/cabinModels";
import { decodeHTMLEntities } from "./utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px auto",
  },
  infoRow: {
    borderBottom: "2px solid rgba(0,0,0,.1)",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "10px",
  },
  infoText: {
    color: " rgba(0,0,0,.3)",
    flex: "1 1 0",
    textAlign: "left",
  },
  infoValue: {
    flex: "1 1 0",
    textAlign: "left",
  },
  descriptionHeader: {
    color: " rgba(0,0,0,.3)",
    flex: "1 1 0",
    textAlign: "left",
    marginBottom: "10px",
  },
  description: {
    whiteSpace: "pre-line",
    maxHeight: "200px",
    overflowY: "scroll",
  },
}));

interface InformationProps {
  loading: boolean;
  cabinInformation: Cabin;
}

function CabinInformation(props: InformationProps) {
  const { loading, cabinInformation } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.infoContainer}>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage id="address" defaultMessage="Osoite" />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.address}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage id="zipCode" defaultMessage="Postinumero" />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.postalNumber}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage id="numberOfPeople" defaultMessage="Henkilömäärä" />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.numberOfBeds}+{cabinInformation.numberOfExtraBeds}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.descriptionHeader}>
          <FormattedMessage id="description" defaultMessage="Kuvaus" />
        </Grid>
        <Grid item className={classes.infoValue}>
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: decodeHTMLEntities(cabinInformation.description),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CabinInformation;
