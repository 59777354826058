import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Grid, MenuItem, Select, Switch } from "@material-ui/core";
import { ExtraService } from "./models/cabinModels";

function createOptions(service: ExtraService) {
  let options = [];

  if (service.min && service.max) {
    for (let i = service.min; i <= service.max; i++) {
      options.push(
        <MenuItem key={`${service.name}_${i}`} value={i}>
          {i}
        </MenuItem>
      );
    }
  }

  return options;
}

const useStyles = makeStyles((theme) => ({
  extraServiceContainer: {},
  serviceNameContainer: {
    display: "flex",
    fontSize: "14px",
    textAlign: "left",
    "& span": {
      margin: "auto 20px",
    },
  },
  selectorContainer: {},
  service: {
    marginTop: "10px",
  },
  menuPaper: {
    maxHeight: "200px",
  },
}));

interface ExtraServiceProps {
  services?: ExtraService[];
  selectedServices: any;
  setSelectedServices: (service: any) => void;
}

function ExtraServices(props: ExtraServiceProps) {
  const classes = useStyles();
  const { services, selectedServices, setSelectedServices } = props;

  if (!services) {
    return null;
  }

  const handleSwitchChange = (id: string) => {
    let newServices = { ...selectedServices };
    newServices[id] = !newServices[id] || newServices[id] === 0 ? 1 : 0;
    setSelectedServices(newServices);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    id: string
  ) => {
    let newServices = { ...selectedServices };
    newServices[id] = event.target.value;
    setSelectedServices(newServices);
  };

  return (
    <div className={classes.extraServiceContainer}>
      {services.map((service: ExtraService, index: number) => {
        return (
          <Grid
            container
            direction="row"
            justify="space-between"
            wrap="nowrap"
            key={`service_${index}`}
            className={classes.service}
          >
            <Grid item className={classes.serviceNameContainer}>
              <span>
                {service.name} ({service.price} €)
                {/* {service.currency}) */}
              </span>
            </Grid>
            <Grid item className={classes.selectorContainer}>
              {service.min === 1 && service.max === 1 ? (
                <Switch
                  checked={selectedServices[service.id] === 1 ? true : false}
                  onChange={() => handleSwitchChange(service.id)}
                  name={service.name}
                  color="primary"
                />
              ) : (
                <FormControl variant="outlined">
                  <Select
                    labelId="select_service_label"
                    id={`select_service_${index}`}
                    value={selectedServices[service.id] || ""}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      handleSelectChange(event, service.id)
                    }
                    defaultValue=""
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    <MenuItem key={`${service.name}_${0}`} value="">
                      {"0"}
                    </MenuItem>
                    {createOptions(service).map((option: any) => {
                      return option;
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

export default ExtraServices;
