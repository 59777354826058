import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import { RootState } from "./redux/reducers";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  seachCabins: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "80vh",
    marginTop: "20px",
  },
  searchForm: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "40px 20px",
    borderRadius: "10px",
    width: "400px",
  },
  confirmationSuccess: {
    fontSize: "24px",
  },
  confirmationEmail: {
    marginTop: "40px",
    marginBottom: "40px",
    fontSize: "16px",
  },
  confirmationInfo: {
    boxShadow: "none",
    "&:before": {
      opacity: 0,
    },
  },
  confirmatioHeader: {
    marginTop: "10px",
    fontSize: "18px",
  },
  infoContainer: {
    fontSize: "14px",
    margin: "0 5px",
  },
  infoValue: {
    fontWeight: "bold",
    maxWidth: "200px",
    textAlign: "right",
  },
  returnButton: {
    margin: "40px auto 0 auto",
    maxWidth: "200px",
  },
}));

type ConfirmedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Confirmed(props: ConfirmedProps) {
  const classes = useStyles();
  const { reservationInfo, resetActiveCabin } = props;
  let history = useHistory();

  if (!reservationInfo) {
    history.push("/login");
    return null;
  }

  const handleReturn = () => {
    resetActiveCabin();
    history.push("/browse");
  };

  return (
    <div id="Search" className={classes.seachCabins}>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.searchForm}
      >
        <span className={classes.confirmationSuccess}>
          <FormattedMessage
            id="reservationConfirmed"
            defaultMessage="Varaus onnistui!"
          />
        </span>
        <span className={classes.confirmationEmail}>
          <FormattedMessage
            id="confirmationEmail"
            defaultMessage="Saat pian tilausvahvistuksen sähköpostiisi."
          />
        </span>

        <Accordion
          classes={{ root: classes.confirmationInfo }}
          defaultExpanded={false}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.confirmatioHeader}
          >
            <FormattedMessage
              id="reservationInfo"
              defaultMessage="Varauksen tiedot"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              direction="column"
              spacing={2}
              className={classes.infoContainer}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Grid item>
                    <FormattedMessage
                      id="reservationId"
                      defaultMessage="Varausnumero"
                    />
                  </Grid>
                  <Grid className={classes.infoValue} item>
                    {reservationInfo.reservationId}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Grid item>
                    <FormattedMessage id="cabinName" defaultMessage="Mökki" />
                  </Grid>
                  <Grid className={classes.infoValue} item>
                    {reservationInfo.cabinName}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Grid item>
                    <FormattedMessage
                      id="reservationName"
                      defaultMessage="Varaaja"
                    />
                  </Grid>
                  <Grid className={classes.infoValue} item>
                    {reservationInfo.name}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Grid item>
                    <FormattedMessage
                      id="reservationEmail"
                      defaultMessage="Sähköposti"
                    />
                  </Grid>
                  <Grid className={classes.infoValue} item>
                    {reservationInfo.email}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Grid item>
                    <FormattedMessage id="starting" defaultMessage="Alkaen" />
                  </Grid>
                  <Grid className={classes.infoValue} item>
                    {reservationInfo.from}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Grid item>
                    <FormattedMessage id="ending" defaultMessage="Päättyy" />
                  </Grid>
                  <Grid className={classes.infoValue} item>
                    {reservationInfo.to}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Button
          className={classes.returnButton}
          variant="contained"
          color="primary"
          onClick={handleReturn}
        >
          <FormattedMessage
            id="returnToFrontPage"
            defaultMessage="Palaa etusivulle"
          />
        </Button>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      resetActiveCabin: actions.resetActiveCabin,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  reservationInfo: state.appState.reservationInfo,
  activeFilters: state.appState.activeFilters,
  cabin: state.appState.activeCabin,
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmed);
