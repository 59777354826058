import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Cabin } from "./models/cabinModels";
import { FormattedMessage } from "react-intl";
import { decodeHTMLEntities } from "./utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px auto",
  },
  infoRow: {
    borderBottom: "2px solid rgba(0,0,0,.1)",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "10px",
  },
  infoText: {
    color: " rgba(0,0,0,.3)",
    flex: "1 1 0",
    textAlign: "left",
  },
  infoValue: {
    flex: "1 1 0",
    textAlign: "left",
  },
}));

interface InformationProps {
  loading: boolean;
  cabinInformation: Cabin;
}

function Equipment(props: InformationProps) {
  const classes = useStyles();
  const { cabinInformation } = props;

  return (
    <Grid className={classes.infoContainer}>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage id="warmArea" defaultMessage="Lämmin pinta-ala" />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.warmHousingArea} m2
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage id="storeyes" defaultMessage="Kerrokset" />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.storeys}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage id="smoking" defaultMessage="Tupakointi" />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.smoking === "0" ? (
            <FormattedMessage id="no" defaultMessage="Ei" />
          ) : (
            <FormattedMessage id="yes" defaultMessage="Kyllä" />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage
            id="buildingMaterial"
            defaultMessage="Rakennusmateriaali"
          />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.buildingMaterial}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage id="bedrooms" defaultMessage="Makuuhuoneita" />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.bedrooms}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.infoRow}
      >
        <Grid item className={classes.infoText}>
          <FormattedMessage
            id="constructionYear"
            defaultMessage="Rakennusvuosi"
          />
        </Grid>
        <Grid item className={classes.infoValue}>
          {cabinInformation.contructionYear}
        </Grid>
      </Grid>
      {cabinInformation.accessories.map((accessory) => (
        <Grid
          key={accessory.id}
          container
          direction="row"
          justify="space-between"
          className={classes.infoRow}
        >
          <Grid
            item
            className={classes.infoText}
            dangerouslySetInnerHTML={{
              __html: decodeHTMLEntities(accessory.name),
            }}
          />
          <Grid item className={classes.infoValue}>
            {accessory.value}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default Equipment;
