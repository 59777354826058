import { DayRange } from "react-modern-calendar-datepicker";
import { Cabin, CabinFilters, ReservationInfo } from "../../models/cabinModels";
import * as actionTypes from "../actionIds";

export const userLogin = (username: string, password: string) => {
  return {
    type: actionTypes.START_USER_LOGIN,
    payload: { username, password },
  };
};

export const saveAccessToken = (token: string) => {
  return {
    type: actionTypes.SAVE_ACCESS_TOKEN,
    payload: { token },
  };
};

export const resetAccessToken = () => {
  return {
    type: actionTypes.RESET_ACCESS_TOKEN,
    payload: null,
  };
};

export const changeActiveFilters = (filters: CabinFilters) => {
  return {
    type: actionTypes.CHANGE_ACTIVE_FILTERS,
    payload: { filters },
  };
};

export const storeCabinForReservation = (cabin: Cabin) => {
  return {
    type: actionTypes.STORE_CABIN_FOR_RESERVATION,
    payload: { cabin },
  };
};

export const resetActiveCabin = () => {
  return {
    type: actionTypes.RESET_ACTIVE_CABIN,
    payload: null,
  };
};

export const handleDateRangeChange = (range: DayRange) => {
  return {
    type: actionTypes.CHANGE_DATERANGE,
    payload: { range },
  };
};

export const storeLanguagePreference = (language: string) => {
  return {
    type: actionTypes.SET_LANGUAGE,
    payload: { language },
  };
};

export const saveReservationInfo = (reservationInfo: ReservationInfo) => {
  return {
    type: actionTypes.SAVE_RESERVATION_INFO,
    payload: { reservationInfo },
  };
};

export const setActivePrice = (price?: number) => {
  return {
    type: actionTypes.SET_ACTIVE_PRICE,
    payload: { price },
  };
};
