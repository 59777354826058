import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RootState } from "./redux/reducers";
import { connect } from "react-redux";
import { Grid, Card } from "@material-ui/core";
import CabinCard from "./CabinCard";
import { useQuery } from "@apollo/client";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import { CABIN_QUERY } from "./api/cabins";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import { Cabin } from "./models/cabinModels";
import { getLanguageId } from "./utils/commonUtils";
import dayjs from "dayjs";
import Search from "./Search";

const useStyles = makeStyles((theme) => ({
  browseCabins: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
    marginTop: "20px",
  },
  inputFilters: {
    marginTop: 20,
    marginBottom: 20,
  },
  editLink: {
    fontSize: "14px",
    fontWeight: "bold",
    alignSelf: "center",
  },
  browseForm: {
    padding: "20px",
    borderRadius: "10px",
    width: "450px",
    [theme.breakpoints.up("md")]: {
      width: 860,
    },
  },
  loadingIndicator: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px auto",
    padding: 20,
    "& .MuiCard-root": {
      width: "100%",
    },
  },
  imageContainer: {
    height: "250px",
    width: "100%",
    WebkitBoxShadow: "0px 0px 15px 0px rgb(23 9 9 / 50%)",
    MozBoxShadow: "0px 0px 15px 0px rgb(23 9 9 / 50%)",
    boxShadow: "0px 0px 15px 0px rgb(23 9 9 / 50%))",
    "& div": {
      maxHeight: "250px",
    },
    "& img": {
      height: "250px",
      objectFit: "cover",
    },
  },
}));

type BrowseProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Browse(props: BrowseProps) {
  const {
    activeFilters,
    language,
    changeActiveFilters,
    decodedAccessToken,
  } = props;
  const classes = useStyles();
  let history = useHistory();

  const { loading, error, data } = useQuery(CABIN_QUERY, {
    variables: {
      startDate: activeFilters.startDate,
      endDate: activeFilters.endDate,
      children: activeFilters.children,
      adults: activeFilters.adults,
      onlyAvailable: activeFilters.onlyAvailable,
      langId: getLanguageId(language),
      ownerNumber: parseInt(decodedAccessToken?.omnro),
    },
  });

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const date = event.target.value
      ? dayjs(event.target.value).format("YYYY-MM-DD")
      : "";

    changeActiveFilters({ ...activeFilters, startDate: date });
  };

  const handleEndDateChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const date = event.target.value
      ? dayjs(event.target.value).format("YYYY-MM-DD")
      : "";

    changeActiveFilters({ ...activeFilters, endDate: date });
  };

  if (error) console.log(`Error! ${error.message}`);

  return (
    <div id="Search" className={classes.browseCabins}>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.browseForm}
      >
        <Search />
        {loading && (
          <>
            <div className={classes.loadingIndicator}>
              <Card>
                <Grid className={classes.imageContainer}>
                  <Skeleton animation="wave" variant="rect" height="100%" />
                </Grid>
              </Card>
            </div>
            <div className={classes.loadingIndicator}>
              <Card>
                <Grid className={classes.imageContainer}>
                  <Skeleton animation="wave" variant="rect" height="100%" />
                </Grid>
              </Card>
            </div>
          </>
        )}
        {!loading &&
          data &&
          data.searchCabins &&
          data.searchCabins.map((cabin: Cabin, index: number) => {
            return (
              <CabinCard
                key={`cabin_card_${index}`}
                cabin={cabin}
                index={index}
              />
            );
          })}
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      resetAccessToken: actions.resetAccessToken,
      changeActiveFilters: actions.changeActiveFilters,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  activeFilters: state.appState.activeFilters,
  language: state.appState.language,
  decodedAccessToken: state.appState.decodedAccessToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(Browse);
