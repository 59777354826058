import React, { useEffect, useState } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./redux/reducers";
import rootSaga from "./redux/sagas";
import { ToastProvider } from "react-toast-notifications";
import { IntlProvider } from "react-intl";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import dayjs from "dayjs";

import messages_fi from "./translations/fi.json";
import messages_en from "./translations/en.json";
import { loadState, saveState } from "./localstorage";

import TahkoApp from "./TahkoApp";

const messages: any = {
  fi: messages_fi,
  en: messages_en,
};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
const persistedState = loadState();
const store = createStore(
  reducer,
  {
    appState: {
      ...persistedState?.appState,
      reservationInfo: undefined,
      activeFilters: {
        adults: persistedState?.appState.activeFilters
          ? persistedState.appState.activeFilters.adults
          : 2,
        children: persistedState?.appState.activeFilters
          ? persistedState.appState.activeFilters.children
          : 1,
        onlyAvailable: true,
        startDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
        endDate: dayjs().add(8, "day").format("YYYY-MM-DD"),
      },
    },
  },
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);
store.subscribe(() => {
  saveState({
    appState: store.getState().appState,
  });
});

function App() {
  const [language, storeLanguagePreference] = useState("fi");

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  useEffect(() => {
    const lang = store.getState().appState.language;
    lang && storeLanguagePreference(lang);
  }, []);

  const authLink = setContext((_, { headers }) => {
    // get token from store
    const token = store.getState().appState.accessToken;
    // return the headers to the context so httpLink can read them
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    }

    return {
      headers: {
        ...headers,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const handleLanguageChange = (lang: string) => {
    storeLanguagePreference(lang);
  };

  return (
    <IntlProvider
      locale={language}
      messages={messages[language]}
      defaultLocale="fi"
    >
      <ToastProvider
        placement="bottom-center"
        autoDismiss={true}
        autoDismissTimeout={4000}
      >
        <Provider store={store}>
          <ApolloProvider client={client}>
            <TahkoApp handleLanguageChange={handleLanguageChange} />
          </ApolloProvider>
        </Provider>
      </ToastProvider>
    </IntlProvider>
  );
}

export default App;
