import { Booking, Day } from "../models/cabinModels";

export function getMonthFromString(mon: string) {
  var d = Date.parse(mon + "1, 2020");
  if (!isNaN(d)) {
    return new Date(d).getMonth() + 1;
  }
  return -1;
}

export interface CustomDate {
  year: number;
  month: number;
  day: number;
  className: string;
}

export function generateUnavailableDates(
  bookingsCalendar: Booking[]
): {
  unavailableDates: CustomDate[];
  possibleEndingDays: CustomDate[];
  possibleStartingDays: CustomDate[];
  raffleDays: CustomDate[];
} {
  let unavailableDates: CustomDate[] = [];
  let possibleEndingDays: CustomDate[] = [];
  let possibleStartingDays: CustomDate[] = [];
  let raffleDays: CustomDate[] = [];

  if (bookingsCalendar && Array.isArray(bookingsCalendar)) {
    bookingsCalendar.forEach(
      (booking: Booking, bookingsCalendarIndex: number) => {
        booking.days.forEach((calendarDay: Day, index: number) => {
          let previousDay: any = {};

          // if we're at first day of the month, look up previous day from previous month (aka. bookings calendar)
          if (index === 0 && bookingsCalendarIndex === 0) {
            previousDay.available = false;
          } else if (index === 0 && bookingsCalendarIndex > 0) {
            const previousCalendar =
              bookingsCalendar[bookingsCalendarIndex - 1];
            previousDay =
              previousCalendar.days[previousCalendar.days.length - 1];
          } else {
            previousDay = booking.days[index - 1];
          }

          if (!calendarDay.available && !previousDay.available) {
            unavailableDates.push({
              year: booking.year,
              month: getMonthFromString(booking.month),
              day: calendarDay.day,
              className: "reserved",
            });
          }

          if (!calendarDay.available && previousDay.available) {
            possibleEndingDays.push({
              year: booking.year,
              month: getMonthFromString(booking.month),
              day: calendarDay.day,
              className: "endingDay",
            });
          }

          if (calendarDay.available && !previousDay.available) {
            possibleStartingDays.push({
              year: booking.year,
              month: getMonthFromString(booking.month),
              day: calendarDay.day,
              className: "startingDay",
            });
          }

          if (calendarDay.raffle) {
            raffleDays.push({
              year: booking.year,
              month: getMonthFromString(booking.month),
              day: calendarDay.day,
              className: "raffleDay",
            });
          }
        });
      }
    );
  }

  const dates = {
    unavailableDates,
    possibleEndingDays,
    possibleStartingDays,
    raffleDays,
  };
  return dates;
}

export function generateReservationPeriods(
  bookingsCalendar: Booking[]
): CustomDate[] {
  let reservationPeriods: CustomDate[] = [];
  if (bookingsCalendar && Array.isArray(bookingsCalendar)) {
    bookingsCalendar.forEach((booking: Booking) => {
      booking.days.forEach((calendarDay: Day) => {
        if (
          calendarDay.reservationPeriod &&
          Array.isArray(calendarDay.reservationPeriod)
        ) {
          const desc = calendarDay.reservationPeriod[0].description
            ? calendarDay.reservationPeriod[0].description
            : "";
          reservationPeriods.push({
            year: booking.year,
            month: getMonthFromString(booking.month),
            day: calendarDay.day,
            className: `period_${desc}`,
          });
        }
      });
    });
  }

  return reservationPeriods;
}
