export const START_USER_LOGIN = "START_USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const CHANGE_DATERANGE = "CHANGE_DATERANGE";
export const SAVE_ACCESS_TOKEN = "SAVE_ACCESS_TOKEN";
export const CHANGE_CABIN_FILTER = "CHANGE_CABIN_FILTER";
export const RESET_ACCESS_TOKEN = "RESET_ACCESS_TOKEN";
export const CHANGE_ACTIVE_FILTERS = "CHANGE_ACTIVE_FILTERS";
export const STORE_CABIN_FOR_RESERVATION = "STORE_CABIN_FOR_RESERVATION";
export const RESET_ACTIVE_CABIN = "RESET_ACTIVE_CABIN";
export const SET_NUMBER_OF_PEOPLE = "SET_NUMBER_OF_PEOPLE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SAVE_RESERVATION_ID = "SAVE_RESERVATION_ID";
export const SAVE_RESERVATION_INFO = "SAVE_RESERVATION_INFO";
export const SET_ACTIVE_PRICE = "SET_ACTIVE_PRICE";
