import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { CABIN_PRICE_QUERY } from "./api/cabins";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import { getLanguageId } from "./utils/commonUtils";
import { RootState } from "./redux/reducers";
import { useQuery } from "@apollo/client";
import { Cabin } from "./models/cabinModels";
import { FormattedMessage } from "react-intl";

interface CustomProps {
  startDate: string | null;
  endDate: string | null;
  cabin: Cabin;
}

type DynamicPriceProps = CustomProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function DynamicPrice(props: DynamicPriceProps) {
  const {
    activeFilters,
    language,
    startDate,
    endDate,
    cabin,
    decodedAccessToken,
    setActivePrice,
  } = props;
  const { loading, error, data } = useQuery(CABIN_PRICE_QUERY, {
    variables: {
      startDate: startDate || activeFilters.startDate,
      endDate: endDate || activeFilters.endDate,
      children: activeFilters.children,
      adults: activeFilters.adults,
      onlyAvailable: activeFilters.onlyAvailable,
      langId: getLanguageId(language),
      ownerNumber: parseInt(decodedAccessToken?.omnro),
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return (
      <Grid item>
        <CircularProgress
          color="primary"
          size="14px"
          style={{ marginLeft: "20px" }}
        />
      </Grid>
    );
  }

  if (data?.searchCabins) {
    const selectedCabin = data.searchCabins.find((data: any) => {
      return data.id === cabin.id;
    });

    if (selectedCabin) {
      setActivePrice(selectedCabin.price);
      return <Grid item>{selectedCabin?.price}€</Grid>;
    } else {
      setActivePrice(undefined);
      return (
        <Grid item>
          <FormattedMessage id="noPrice" defaultMessage="Price not found" />
        </Grid>
      );
    }
  } else {
    return (
      <Grid item>
        <FormattedMessage id="noPrice" defaultMessage="Price not found" />
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      storeCabinForReservation: actions.storeCabinForReservation,
      setActivePrice: actions.setActivePrice,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  language: state.appState.language,
  activeFilters: state.appState.activeFilters,
  decodedAccessToken: state.appState.decodedAccessToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicPrice);
