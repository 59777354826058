import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login(
    $email: String!
    $key: String!
    $company: String!
    $password: String!
    $name: String!
  ) {
    login(
      name: $name
      company: $company
      password: $password
      email: $email
      key: $key
    ) {
      accessToken
    }
  }
`;
