import { put, takeLatest, delay } from "redux-saga/effects";
import * as actionTypes from "../actionIds";

export function* watchLoginRequests() {
  yield takeLatest(actionTypes.START_USER_LOGIN, login);
}

function* login() {
  try {
    yield delay(1000);
    const results = "success";
    yield put({ type: actionTypes.USER_LOGIN_SUCCESS, results });
  } catch (e) {
    yield put({ type: actionTypes.USER_LOGIN_FAILURE, e });
  }
}
