import React, { useEffect, useState } from "react";
import { RootState } from "./redux/reducers";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Switch,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { CABIN_COUNT_QUERY } from "./api/cabins";

const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const useStyles = makeStyles((theme) => ({
  seachCabins: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
  header: {
    fontSize: "20px",
    marginBottom: "20px",
  },
  searchForm: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "40px",
    borderRadius: "10px",
    width: "400px",
  },
  gridRow: {
    width: "100%",
  },
  switchContainer: {
    marginTop: "15px",
    marginLeft: "15px",
  },
  selectForm: {
    width: "100%",
  },
  selectContainer: {
    flexGrow: 1,
  },
  buttonContainer: {
    margin: "40px 0 20px 0",
  },
}));

type SearchProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Search(props: SearchProps) {
  const { activeFilters, changeActiveFilters, decodedAccessToken } = props;
  const { startDate, endDate, children, adults, onlyAvailable } = activeFilters;
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  let history = useHistory();
  const classes = useStyles();
  const { loading, error, data } = useQuery(CABIN_COUNT_QUERY, {
    variables: {
      startDate,
      endDate,
      adults: parseInt(adults),
      children: parseInt(children),
      onlyAvailable,
      langId: 1,
      ownerNumber: parseInt(decodedAccessToken?.omnro),
    },
  });

  if (error) {
    console.log("error:", error);
    props.resetAccessToken();
    history.push("/login");
  }

  useEffect(() => {
    if (stateLoading !== loading) {
      setStateLoading(loading);
    }
  }, [loading, stateLoading]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeActiveFilters({ ...activeFilters, onlyAvailable: !onlyAvailable });
  };

  const handleAdultsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const number = parseInt(event.target.value as string, 10);
    changeActiveFilters({ ...activeFilters, adults: number });
  };

  const handleChildrenChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const number = parseInt(event.target.value as string, 10);
    changeActiveFilters({ ...activeFilters, children: number });
  };

  const handleSubmit = () => {
    history.push("/browse");
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const date = event.target.value
      ? dayjs(event.target.value).format("YYYY-MM-DD")
      : "";

    changeActiveFilters({ ...activeFilters, startDate: date });
  };

  const handleEndDateChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const date = event.target.value
      ? dayjs(event.target.value).format("YYYY-MM-DD")
      : "";

    changeActiveFilters({ ...activeFilters, endDate: date });
  };

  return (
    <div id="Search" className={classes.seachCabins}>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.searchForm}
      >
        <Grid item className={classes.header}>
          <FormattedMessage id="findCabins" defaultMessage="Etsi mökkejä" />
        </Grid>
        <Grid item className={classes.gridRow}>
          <TextField
            variant="outlined"
            color="primary"
            label={<FormattedMessage id="starting" defaultMessage="Alkaen" />}
            fullWidth
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </Grid>
        <Grid item className={classes.gridRow}>
          <TextField
            variant="outlined"
            color="primary"
            label={<FormattedMessage id="ending" defaultMessage="Päättyy" />}
            fullWidth
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </Grid>
        <Grid item className={classes.gridRow}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            spacing={2}
            justify="center"
          >
            <Grid item className={classes.selectContainer}>
              <FormControl variant="outlined" className={classes.selectForm}>
                <InputLabel id="demo-simple-select-outlined-label">
                  <FormattedMessage id="adults" defaultMessage="Aikuisia" />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={adults}
                  onChange={handleAdultsChange}
                  label={
                    <FormattedMessage id="adults" defaultMessage="Aikuisia" />
                  }
                >
                  {numberArray.map((item: number) => {
                    return (
                      <MenuItem key={`adult_${item}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item className={classes.selectContainer}>
              <FormControl variant="outlined" className={classes.selectForm}>
                <InputLabel id="demo-simple-select-outlined-label">
                  <FormattedMessage id="children" defaultMessage="Lapsia" />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={children}
                  onChange={handleChildrenChange}
                  label={
                    <FormattedMessage id="children" defaultMessage="Lapsia" />
                  }
                >
                  <MenuItem key={`children_${0}`} value={"0"}>
                    {0}
                  </MenuItem>
                  {numberArray.map((item: number) => {
                    return (
                      <MenuItem key={`child_${item}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" spacing={4}>
          <Grid item className={classes.switchContainer}>
            <FormControlLabel
              control={
                <Switch
                  checked={onlyAvailable}
                  onChange={handleSwitchChange}
                  name="onlyFreeCabins"
                  color="primary"
                />
              }
              label={
                <FormattedMessage
                  id="availableOnly"
                  defaultMessage="Vain vapaat"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      resetAccessToken: actions.resetAccessToken,
      changeActiveFilters: actions.changeActiveFilters,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  accessToken: state.appState.accessToken,
  activeFilters: state.appState.activeFilters,
  decodedAccessToken: state.appState.decodedAccessToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
