import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notFound: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100vh",
  },
  info: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "20px",
    borderRadius: "10px",
    width: "450px",
  },
}));

function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.notFound}>
      <div className={classes.info}>
        <h2>
          <FormattedMessage id="notFound" defaultMessage="Sivua ei löydetty." />
        </h2>
        <Link to="/">
          <FormattedMessage id="goHome" defaultMessage="Palaa etusivulle" />
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
