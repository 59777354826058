import React, { useEffect, useState } from "react";
import { RootState } from "./redux/reducers";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import NavBar from "./Navbar";
import LoginScreen from "./LoginScreen";
import TermsAndServices from "./TermsAndServices";
import Browse from "./Browse";
import Reservation from "./Reservation";
import Confirmed from "./Confirmed";
import NotFound from "./NotFound";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

function PrivateRoute({ children, ...rest }: any, isAuthenticated: boolean) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

interface CustomProps {
  handleLanguageChange: (lang: string) => void;
}

type TahkoAppProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  CustomProps;

function TahkoApp(props: TahkoAppProps) {
  const { isAuthenticated, handleLanguageChange, decodedAccessToken } = props;
  const [color, setColor] = useState("#ED1C24");

  useEffect(() => {
    if (decodedAccessToken && decodedAccessToken.main_bg_color) {
      setColor("#" + decodedAccessToken.main_bg_color);
    } else {
      setColor("#ED1C24");
    }
  }, [decodedAccessToken]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: color,
      },
      secondary: {
        main: "#ffffff",
      },
    },
    overrides: {
      MuiTableCell: {
        root: {
          maxWidth: "150px",
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="TahkoApp">
        <Router>
          <NavBar handleLanguageChange={handleLanguageChange} />
          <TermsAndServices />
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login">
              <LoginScreen directLogin={false} />
            </Route>
            <Route path="/login/:company/:key">
              <LoginScreen directLogin={true} />
            </Route>
            {/* <PrivateRoute path="/search" isAuthenticated={isAuthenticated}>
              <Search />
            </PrivateRoute> */}
            <PrivateRoute path="/browse" isAuthenticated={isAuthenticated}>
              <Browse />
            </PrivateRoute>
            <PrivateRoute
              path="/reservation/:id"
              isAuthenticated={isAuthenticated}
            >
              <Reservation />
            </PrivateRoute>
            <PrivateRoute path="/confirmed" isAuthenticated={isAuthenticated}>
              <Confirmed />
            </PrivateRoute>
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      resetAccessToken: actions.resetAccessToken,
      changeActiveFilters: actions.changeActiveFilters,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  decodedAccessToken: state.appState.decodedAccessToken,
  isAuthenticated: state.appState.isAuthenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(TahkoApp);
