import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { RootState } from "./redux/reducers";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { FormattedMessage, useIntl } from "react-intl";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },
  icon: {
    color: "#FFFFFF",
  },
  language: {
    marginLeft: "57px",
  },
}));

interface OwnProps {
  handleLanguageChange: (lang: string) => void;
}

type NavBarProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

function NavBar(props: NavBarProps) {
  const classes = useStyles();
  const intl = useIntl();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  let history = useHistory();
  const {
    storeLanguagePreference,
    resetAccessToken,
    handleLanguageChange,
  } = props;

  const handleLogoClick = () => {
    history.push("/");
  };

  const toggleMenu = (show: boolean) => {
    setShowMenu(show);
  };

  const changeLanguage = (lang: string) => {
    storeLanguagePreference(lang);
    handleLanguageChange(lang);
    toggleMenu(false);
  };

  const handleLogout = () => {
    resetAccessToken();
    history.push("/");
    toggleMenu(false);
  };

  return (
    <div className="NavBar">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => toggleMenu(!showMenu)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
            onClick={handleLogoClick}
          >
            Tahko
          </Typography>
        </Toolbar>
        <Drawer
          anchor="top"
          open={showMenu}
          onClose={() => toggleMenu(false)}
          classes={{ paper: classes.paper }}
        >
          <List>
            <ListItem>
              <ListItemIcon color="secondary" classes={{ root: classes.icon }}>
                <LanguageIcon />
              </ListItemIcon>

              <FormattedMessage
                id="changeLanguage"
                defaultMessage="Vaihda kieli:"
              />
            </ListItem>
            <ListItem
              className={classes.language}
              button
              key={"Suomeksi"}
              onClick={() => changeLanguage("fi")}
            >
              <ListItemText primary={"Suomeksi"} />
            </ListItem>
            <ListItem
              className={classes.language}
              button
              key={"In English"}
              onClick={() => changeLanguage("en")}
            >
              <ListItemText primary={"In English"} />
            </ListItem>
            <Divider />
            <ListItem
              button
              key={intl.formatMessage({
                id: "logout",
                defaultMessage: "Kirjaudu ulos",
              })}
              onClick={handleLogout}
            >
              <ListItemIcon color="secondary" classes={{ root: classes.icon }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({
                  id: "logout",
                  defaultMessage: "Kirjaudu ulos",
                })}
              />
            </ListItem>
          </List>
        </Drawer>
      </AppBar>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      resetAccessToken: actions.resetAccessToken,
      storeLanguagePreference: actions.storeLanguagePreference,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  language: state.appState.language,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
