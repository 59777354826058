import { DayRange } from "react-modern-calendar-datepicker";
import dayjs from "dayjs";

export function getLanguageId(lang: string) {
  switch (lang) {
    case "fi":
      return 1;
    case "en":
      return 4;
    default:
      return 1;
  }
}

export function decodeHTMLEntities(text: string) {
  if (text) {
    var entities = [
      ["amp", "&"],
      ["apos", "'"],
      ["#x27", "'"],
      ["#x2F", "/"],
      ["#39", "'"],
      ["#47", "/"],
      ["lt", "<"],
      ["gt", ">"],
      ["nbsp", " "],
      ["quot", '"'],
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
      text = text.replace(
        new RegExp("&" + entities[i][0] + ";", "g"),
        entities[i][1]
      );
  }

  return text;
}

export function getStartDateFromDayRange(range: DayRange) {
  if (range && range.from) {
    const year = range.from.year || new Date().getFullYear();
    const month = range.from.month || new Date().getMonth() - 1;
    const day = range.from.day;
    const fromDate = dayjs(new Date(year, month - 1, day)).format("YYYY-MM-DD");
    return fromDate;
  }

  return null;
}

export function getEndDateFromDayRange(range: DayRange) {
  if (range && range.to) {
    const year = range.to.year || new Date().getFullYear();
    const month = range.to.month || new Date().getMonth() - 1;
    const day = range.to.day;
    const toDate = dayjs(new Date(year, month - 1, day)).format("YYYY-MM-DD");
    return toDate;
  }

  return null;
}
