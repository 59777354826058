import { combineReducers } from "redux";
import appState from "./reducers";

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  appState: appState,
});

export default rootReducer;
