import { gql } from "@apollo/client";

export const CABIN_COUNT_QUERY = gql`
  query GetCabinIds(
    $startDate: String!
    $endDate: String!
    $adults: Int!
    $children: Int!
    $onlyAvailable: Boolean!
    $langId: Int!
    $ownerNumber: Int!
  ) {
    searchCabins(
      langId: $langId
      starting: $startDate
      ending: $endDate
      adults: $adults
      children: $children
      ownerNumber: $ownerNumber
      showOnlyAvailableCabins: $onlyAvailable
    ) {
      id
    }
  }
`;

export const CABIN_QUERY = gql`
  query GetCabins(
    $startDate: String!
    $endDate: String!
    $adults: Int!
    $children: Int!
    $onlyAvailable: Boolean!
    $langId: Int!
    $ownerNumber: Int!
  ) {
    searchCabins(
      langId: $langId
      starting: $startDate
      ending: $endDate
      adults: $adults
      children: $children
      ownerNumber: $ownerNumber
      showOnlyAvailableCabins: $onlyAvailable
    ) {
      id
      name
      price
      description
      mainPictureUrl
      secondaryPictureUrls
      rating
      numberOfBeds
      numberOfExtraBeds
      reservationStartTime
      reservationEndTime
      extraServices(lang: $langId) {
        id
        name
        price
        min
        max
      }
    }
  }
`;

export const CABIN_PRICE_QUERY = gql`
  query GetCabins(
    $startDate: String!
    $endDate: String!
    $adults: Int!
    $children: Int!
    $onlyAvailable: Boolean!
    $langId: Int!
    $ownerNumber: Int!
  ) {
    searchCabins(
      langId: $langId
      starting: $startDate
      ending: $endDate
      adults: $adults
      children: $children
      ownerNumber: $ownerNumber
      showOnlyAvailableCabins: $onlyAvailable
    ) {
      id
      price
    }
  }
`;

export const CABIN_INFO_QUERY = gql`
  query GetCabinBookings(
    $cabinId: ID!
    $months: [Month]!
    $langId: Int!
    $ownerNumber: Int!
  ) {
    cabinInformation(
      cabinId: $cabinId
      ownerNumber: $ownerNumber
      langId: $langId
    ) {
      name
      address
      postalNumber
      description
      renovated
      warmHousingArea
      storeys
      smoking
      buildingMaterial
      bedrooms
      contructionYear
      numberOfBeds
      numberOfExtraBeds
      contructionYear
      renovated
      distances {
        id
        name
        distance
      }
      accessories {
        id
        name
        value
      }
      extraServices(lang: $langId) {
        id
        name
        price
        min
        max
      }
      bookingsCalendar(months: $months) {
        id
        year
        month
        days {
          id
          day
          weekDay
          available
          minReservationPeriod
          maxReservationPeriod
          raffle
          reservationPeriod {
            id
            day
            weekDay
            available
            description
          }
        }
      }
    }
  }
`;

export const CREATE_RESERVATION = gql`
  mutation CreateReservation($reservationInput: CabinReservationInput!) {
    reservation(reservation: $reservationInput) {
      id
      info
    }
  }
`;
