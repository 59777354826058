import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage } from "react-intl";
import ExtraServices from "./ExtraServices";
import Prices from "./Prices";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import { RootState } from "./redux/reducers";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import {
  CabinReservationInput,
  ExtraService,
  ExtraServiceInput,
} from "./models/cabinModels";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@apollo/client";
import { CREATE_RESERVATION } from "./api/cabins";
import {
  getEndDateFromDayRange,
  getStartDateFromDayRange,
} from "./utils/commonUtils";

function formDropdownOptions(
  totalNumberOfBeds: number,
  activeNumberOfBeds: number
) {
  let menuItems: any = [];
  const maxDropdownCount = totalNumberOfBeds - activeNumberOfBeds;
  for (let i = 1; i <= maxDropdownCount; i++) {
    menuItems.push(i);
  }
  return menuItems;
}

function getSelectedExtraServices(
  services: ExtraService[],
  selectedServices: any
): ExtraServiceInput[] {
  let serviceSummary: ExtraServiceInput[] = [];

  Object.keys(selectedServices).forEach((key: string) => {
    if (selectedServices[key] > 0) {
      let origService = services.find((s: ExtraService) => {
        return s.id === key;
      });

      origService &&
        serviceSummary.push({
          id: origService.id,
          name: origService.name,
          price: origService.price,
          quantity: selectedServices[key],
        });
    }
  });

  return serviceSummary;
}

interface IFormInput {
  startDate: string;
  endDate: string;
  name: string;
  phoneNumber: string;
  email: string;
  note: string;
}

function getHelperText(field: any) {
  if (field?.message) {
    return field.message;
  }

  if (field) {
    return "This field is required";
  }

  return null;
}

const useStyles = makeStyles((theme) => ({
  reservationContainer: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "20px",
    marginTop: "20px",
    borderRadius: "10px",
    maxWidth: "450px",
  },
  browseCabins: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
  },
  searchForm: {},
  infoRow: {
    fontSize: "12px",
    lineHeight: "16px",
    padding: "10px",
  },
  inputFieldContainer: {
    flex: "1 1 0",
    textAlign: "left",
  },
  selectForm: {
    width: "100%",
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1) !important",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(12px, -6px) scale(0.75) !important",
    },
  },
  extraInformation: {
    boxShadow: "none",
    "&:before": {
      opacity: 0,
    },
  },
  separator: {
    margin: "10px 30px",
    borderBottom: "2px solid rgba(0,0,0,0.1)",
  },
  reservationButton: {
    margin: "10px 0",
  },
  extraServiceHeader: {
    marginTop: "10px",
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

function Separator() {
  const classes = useStyles();
  return <div className={classes.separator}></div>;
}

type LoginScreenProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Reservation(props: LoginScreenProps) {
  const classes = useStyles();
  const { addToast } = useToasts();
  let history = useHistory();
  const {
    dateRange,
    cabin,
    resetActiveCabin,
    decodedToken,
    activeFilters,
    saveReservationInfo,
    reservationInfo,
    activePrice,
    setActivePrice,
  } = props;

  const totalNumberOfBeds = cabin.numberOfBeds + cabin.numberOfExtraBeds;

  const defaultValues = {
    startDate: "",
    endDate: "",
    name: "",
    phoneNumber: "",
    email: decodedToken?.email || "",
    note: "",
  };

  useEffect(() => {
    return () => {
      setActivePrice(undefined);
    };
  }, [setActivePrice]);

  const [adults, setAdults] = useState<string>(activeFilters.adults || "");
  const [children, setChildren] = useState<string>(
    activeFilters.children || ""
  );
  const { handleSubmit, errors: fieldsErrors, control } = useForm<IFormInput>({
    defaultValues,
    shouldFocusError: true,
  });

  useEffect(() => {
    if (reservationInfo && reservationInfo.reservationId) {
      history.push("/confirmed");
    }
  }, [reservationInfo, history]);

  const [selectedServices, setSelectedServices] = useState<any>({});
  const [gqlReservation, { error }] = useMutation(CREATE_RESERVATION);

  error && console.log("error:", error);

  const extraServices =
    getSelectedExtraServices(cabin?.extraServices, selectedServices) || [];

  // On submit, create reservation and redirect to reservation confirmation screen
  const onSubmit = (formData: IFormInput) => {
    try {
      const reservationInput: CabinReservationInput = {
        cabinId: cabin.id,
        start: getStartDateFromDayRange(dateRange) || "",
        end: getEndDateFromDayRange(dateRange) || "",
        adults: parseInt(adults, 10),
        children: parseInt(children, 10),
        extraServices,
        userInformation: {
          id: parseInt(decodedToken.id),
          customerId: parseInt(decodedToken.id),
          name: formData.name,
          phoneNumber: formData.phoneNumber,
          email: formData.email,
          noteOrReference: formData.note,
        },
      };

      gqlReservation({ variables: { reservationInput } }).then(
        (response: any) => {
          const reservation = response?.data?.reservation;
          if (reservation && reservation.id) {
            saveReservationInfo({
              reservationId: reservation.id,
              cabinAddress: cabin.address,
              cabinName: cabin.name,
              name: formData.name,
              email: formData.email,
              from: activeFilters.startDate,
              to: activeFilters.endDate,
            });
          } else {
            addToast(
              <FormattedMessage
                id="errorCreatingReservation"
                defaultMessage="Virhe tilausta luodessa. Yritä myöhemmin uudestaan."
              />,
              {
                appearance: "error",
              }
            );
          }
        }
      );
    } catch (ex) {
      console.log("Error creating and submitting a reservation...");
    }
  };

  // On validation error
  const onError = (errors: any, e: any) => {
    addToast(
      <FormattedMessage
        id="pleaseFillForm"
        defaultMessage="Täytä kaikki vaaditut kentät ennen varauksen tekoa."
      />,
      {
        appearance: "error",
      }
    );
    window.scrollTo(0, 0);
  };

  const handleAdultsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAdults(event.target.value as string);
  };

  const handleChildrenChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setChildren(event.target.value as string);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!cabin) {
      history.push("/browse");
    }
  }, [cabin, history]);

  const fromDate = dayjs(
    new Date(
      dateRange.from?.year,
      dateRange.from?.month - 1,
      dateRange.from?.day
    )
  ).format("DD.MM.YYYY");

  const toDate = dayjs(
    new Date(dateRange.to?.year, dateRange.to?.month - 1, dateRange.to?.day)
  ).format("DD.MM.YYYY");

  const cancelReservation = () => {
    resetActiveCabin();
    history.push("/browse");
  };

  return (
    <div className={classes.browseCabins}>
      <Grid className={classes.reservationContainer}>
        <h2 id="cabin-reservation-header">{cabin.name}</h2>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.infoRow}
            spacing={2}
          >
            <Grid item className={classes.inputFieldContainer}>
              <TextField
                size="small"
                variant="outlined"
                color="primary"
                label={
                  <FormattedMessage
                    id="startingDate"
                    defaultMessage="Aloituspäivä"
                  />
                }
                fullWidth
                type="string"
                disabled={true}
                name="startDate"
                helperText={getHelperText(fieldsErrors.startDate)}
                error={fieldsErrors?.startDate ? true : false}
                value={fromDate}
              />
            </Grid>
            <Grid item className={classes.inputFieldContainer}>
              <TextField
                size="small"
                variant="outlined"
                color="primary"
                label={
                  <FormattedMessage
                    id="enDate"
                    defaultMessage="Loppumispäivä"
                  />
                }
                fullWidth
                type="string"
                disabled={true}
                name="endDate"
                helperText={getHelperText(fieldsErrors.endDate)}
                error={fieldsErrors?.endDate ? true : false}
                value={toDate}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.infoRow}
          >
            <Grid item className={classes.inputFieldContainer}>
              <Controller
                name="name"
                as={
                  <TextField
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={<FormattedMessage id="name" defaultMessage="Nimi" />}
                    fullWidth
                    type="string"
                    name="name"
                    helperText={getHelperText(fieldsErrors.name)}
                    error={fieldsErrors?.name ? true : false}
                  />
                }
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.infoRow}
          >
            <Grid item className={classes.inputFieldContainer}>
              <Controller
                name="phoneNumber"
                as={
                  <TextField
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={
                      <FormattedMessage
                        id="phoneNumber"
                        defaultMessage="Puhelinnumero"
                      />
                    }
                    fullWidth
                    type="string"
                    name="phoneNumber"
                    helperText={getHelperText(fieldsErrors.phoneNumber)}
                    error={fieldsErrors?.phoneNumber ? true : false}
                  />
                }
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
                    message: "invalid phone number",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.infoRow}
          >
            <Grid item className={classes.inputFieldContainer}>
              <Controller
                name="email"
                as={
                  <TextField
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={
                      <FormattedMessage
                        id="email"
                        defaultMessage="Sähköposti"
                      />
                    }
                    fullWidth
                    type="string"
                    name="email"
                    helperText={getHelperText(fieldsErrors.email)}
                    error={fieldsErrors?.email ? true : false}
                  />
                }
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.infoRow}
          >
            <Grid item className={classes.inputFieldContainer}>
              <Controller
                name="note"
                as={
                  <TextField
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={
                      <FormattedMessage
                        id="referenceOrNote"
                        defaultMessage="Viite/Viesti"
                      />
                    }
                    fullWidth
                    type="string"
                    name="note"
                    helperText={getHelperText(fieldsErrors.note)}
                    error={fieldsErrors?.email ? true : false}
                  />
                }
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.infoRow}
            spacing={2}
          >
            <Grid item className={classes.inputFieldContainer}>
              <FormControl variant="outlined" className={classes.selectForm}>
                <InputLabel id="demo-simple-select-outlined-label">
                  <FormattedMessage id="adults" defaultMessage="Aikuisia" />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={adults || ""}
                  onChange={handleAdultsChange}
                  label={
                    <FormattedMessage id="adults" defaultMessage="Aikuisia" />
                  }
                  style={{
                    height: "45px",
                  }}
                >
                  <MenuItem key={`adult_${0}`} value={"0"}>
                    {0}
                  </MenuItem>
                  {formDropdownOptions(
                    totalNumberOfBeds,
                    parseInt(children)
                  ).map((item: number) => {
                    return (
                      <MenuItem key={`adult_${item}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item className={classes.inputFieldContainer}>
              <FormControl variant="outlined" className={classes.selectForm}>
                <InputLabel id="demo-simple-select-outlined-label">
                  <FormattedMessage id="children" defaultMessage="Lapsia" />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={children || ""}
                  onChange={handleChildrenChange}
                  label={
                    <FormattedMessage id="children" defaultMessage="Lapsia" />
                  }
                  style={{
                    height: "45px",
                  }}
                >
                  <MenuItem key={`adult_${0}`} value={"0"}>
                    {0}
                  </MenuItem>
                  {formDropdownOptions(totalNumberOfBeds, parseInt(adults)).map(
                    (item: number) => {
                      return (
                        <MenuItem key={`adult_${item}`} value={item}>
                          {item}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Accordion
            classes={{ root: classes.extraInformation }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.extraServiceHeader}
            >
              <FormattedMessage
                id="extraServices"
                defaultMessage="Lisäpalvelut"
              />
            </AccordionSummary>
            <AccordionDetails>
              <ExtraServices
                services={cabin?.extraServices}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
            </AccordionDetails>
          </Accordion>
          <Separator />
          <Prices
            selectedExtraServices={getSelectedExtraServices(
              cabin?.extraServices,
              selectedServices
            )}
            cabin={cabin}
            activePrice={activePrice}
          />
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.reservationButton}
          >
            <Grid item>
              <Grid container direction="row" spacing={2} wrap="nowrap">
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={cancelReservation}
                  >
                    <FormattedMessage id="cancel" defaultMessage="Peruuta" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" color="primary" variant="contained">
                    <FormattedMessage
                      id="makeReservation"
                      defaultMessage="Varaa"
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      resetActiveCabin: actions.resetActiveCabin,
      saveReservationInfo: actions.saveReservationInfo,
      setActivePrice: actions.setActivePrice,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  dateRange: state.appState.dateRange,
  cabin: state.appState.activeCabin,
  decodedToken: state.appState.decodedAccessToken,
  activeFilters: state.appState.activeFilters,
  reservationInfo: state.appState.reservationInfo,
  activePrice: state.appState.activePrice,
});

export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
