import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

import { FormattedMessage } from "react-intl";
import { Button, Link, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: "20px",
    position: "fixed",
    zIndex: 20,
    left: 0,
    right: 0,
  },
  link: {
    maxWidth: "300px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function TermsAndServices() {
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState<boolean>(true);

  if (!showMenu) {
    return null;
  }

  return (
    <Alert
      className={classes.root}
      severity="warning"
      action={
        <Button color="inherit" size="small" onClick={() => setShowMenu(false)}>
          OK
        </Button>
      }
    >
      <Link
        href="https://www.tahko.com/fi/kauppa/html/rekisteriseloste/"
        target="_blank"
        className={classes.link}
        underline="always"
      >
        <Typography noWrap={false}>
          <FormattedMessage
            id="termsAndServices"
            defaultMessage="Käyttämällä palvelua hyväksyt käyttöehtomme ja sitoudut noudattamaan DPA sopimuksessamme olevia kohtia."
          />
        </Typography>
      </Link>
    </Alert>
  );
}

export default TermsAndServices;
