import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { Cabin, ExtraServiceInput } from "./models/cabinModels";
import { setActivePrice } from "./redux/actions";

function extraServiceSum(services: ExtraServiceInput[]) {
  return services.reduce(
    (sum, service) => sum + service.price * service.quantity,
    0
  );
}

const useStyles = makeStyles((theme) => ({
  priceContainer: {
    margin: "0px 15px",
  },
  priceHeader: {
    textAlign: "left",
  },
  priceItemContainer: {
    margin: "10px 0",
  },
  totalPriceItemContainer: {
    margin: "20px 0",
  },
  priceItem: {
    flex: "1 1 0",
    textAlign: "left",
  },
  total: {
    fontWeight: "bold",
  },
  grayText: {
    color: "rgba(0,0,0,0.5)",
  },
}));

interface PricesProps {
  selectedExtraServices: ExtraServiceInput[];
  cabin: Cabin;
  activePrice?: number;
}

function Prices(props: PricesProps) {
  const classes = useStyles();
  const { selectedExtraServices, cabin, activePrice } = props;

  return (
    <Grid className={classes.priceContainer}>
      <h3 className={classes.priceHeader}>
        <FormattedMessage id="price" defaultMessage="Hinta" />
      </h3>
      <Grid container direction="column">
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            className={classes.priceItemContainer}
          >
            <Grid item className={classes.priceItem}>
              <span className={classes.grayText}>
                <FormattedMessage id="basePrice" defaultMessage="Perusmaksu" />
              </span>
            </Grid>
            <Grid item className={classes.priceItem}>
              <span className={classes.grayText}>
                {activePrice || cabin.price} €
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.priceItemContainer}
          >
            {selectedExtraServices.map((service: ExtraServiceInput) => {
              if (service && service.quantity > 0) {
                return (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    wrap="nowrap"
                    className={classes.priceItemContainer}
                    key={`service_${service.id}`}
                  >
                    <Grid item className={classes.priceItem}>
                      <span className={classes.grayText}>{service.name} </span>
                      {service.quantity > 1 && (
                        <span className={classes.grayText}>
                          ({service.quantity})
                        </span>
                      )}
                    </Grid>
                    <Grid item className={classes.priceItem}>
                      <span className={classes.grayText}>
                        {service.quantity * service.price} €
                      </span>
                    </Grid>
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            className={classes.totalPriceItemContainer}
          >
            <Grid item className={classes.priceItem}>
              <span className={classes.total}>
                <FormattedMessage id="total" defaultMessage="Yhteensä" />{" "}
              </span>
            </Grid>
            <Grid item className={classes.priceItem}>
              <span className={classes.total}>
                {activePrice
                  ? activePrice + extraServiceSum(selectedExtraServices)
                  : cabin.price + extraServiceSum(selectedExtraServices)}{" "}
                €
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Prices;
