import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Cabin } from "./models/cabinModels";
import { decodeHTMLEntities } from "./utils/commonUtils";

function convertDistance(distance: string) {
  try {
    const numberDistance = parseFloat(distance);
    if (numberDistance < 1) {
      const meters = numberDistance * 1000;
      return meters + " m";
    } else {
      return numberDistance + " km";
    }
  } catch (ex) {
    return distance + " km";
  }
}

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px auto",
  },
  infoRow: {
    borderBottom: "2px solid rgba(0,0,0,.1)",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "10px",
  },
  infoText: {
    color: " rgba(0,0,0,.3)",
    flex: "1 1 0",
    textAlign: "left",
  },
  infoValue: {
    flex: "1 1 0",
    textAlign: "left",
  },
}));

interface InformationProps {
  loading: boolean;
  cabinInformation: Cabin;
}

function Distances(props: InformationProps) {
  const classes = useStyles();
  const { cabinInformation } = props;

  return (
    <Grid className={classes.infoContainer}>
      {cabinInformation.distances.map((distance) => (
        <Grid
          key={distance.id}
          container
          direction="row"
          justify="space-between"
          className={classes.infoRow}
        >
          <Grid
            item
            className={classes.infoText}
            dangerouslySetInnerHTML={{
              __html: decodeHTMLEntities(distance.name),
            }}
          />
          <Grid item className={classes.infoValue}>
            {convertDistance(distance.distance)}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default Distances;
