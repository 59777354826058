import { DayRange } from "react-modern-calendar-datepicker";
import { Cabin, CabinFilters, ReservationInfo } from "../../models/cabinModels";
import * as actionTypes from "../actionIds";
import jwt_decode from "jwt-decode";

export interface AppState {
  isAuthenticated: boolean;
  isLogginIn: boolean;
  dateRange?: DayRange;
  accessToken: string | null;
  activeFilters?: CabinFilters;
  activeCabin?: Cabin;
  language: string;
  decodedAccessToken?: any;
  reservationInfo?: ReservationInfo;
  activePrice?: number;
}

const initialState: AppState = {
  isAuthenticated: false,
  isLogginIn: false,
  dateRange: {
    from: null,
    to: null,
  },
  accessToken: null,
  language: "fi",
};

export default (prevState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.START_USER_LOGIN:
      return Object.assign({}, prevState, {
        isLogginIn: true,
        isAuthenticated: false,
      });
    case actionTypes.USER_LOGIN_SUCCESS:
      return Object.assign({}, prevState, {
        isLogginIn: false,
        isAuthenticated: true,
      });
    case actionTypes.USER_LOGIN_FAILURE:
      return Object.assign({}, prevState, {
        isLogginIn: false,
        isAuthenticated: false,
      });
    case actionTypes.CHANGE_DATERANGE:
      return Object.assign({}, prevState, {
        dateRange: action.payload?.range,
      });
    case actionTypes.SAVE_ACCESS_TOKEN:
      return Object.assign({}, prevState, {
        accessToken: action.payload?.token,
        decodedAccessToken: jwt_decode(action.payload?.token),
        isAuthenticated: action.payload?.token ? true : false,
      });
    case actionTypes.RESET_ACCESS_TOKEN:
      return Object.assign({}, prevState, {
        accessToken: null,
        decodedAccessToken: null,
        isAuthenticated: false,
        reservationInfo: undefined,
      });
    case actionTypes.CHANGE_ACTIVE_FILTERS:
      return Object.assign({}, prevState, {
        activeFilters: action.payload?.filters,
      });
    case actionTypes.STORE_CABIN_FOR_RESERVATION:
      return Object.assign({}, prevState, {
        activeCabin: action.payload?.cabin,
      });
    case actionTypes.RESET_ACTIVE_CABIN:
      return Object.assign({}, prevState, {
        activeCabin: undefined,
        reservationInfo: undefined,
      });
    case actionTypes.SET_LANGUAGE:
      return Object.assign({}, prevState, {
        language: action.payload?.language,
      });
    case actionTypes.SAVE_RESERVATION_INFO:
      return Object.assign({}, prevState, {
        reservationInfo: action.payload?.reservationInfo,
      });
    case actionTypes.SET_ACTIVE_PRICE:
      return Object.assign({}, prevState, {
        activePrice: action.payload?.price,
      });
    default:
      return prevState;
  }
};
