import React, { useEffect, useState } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import logo from "./images/tahkoliehu.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import { RootState } from "./redux/reducers";
import { useToasts } from "react-toast-notifications";
import { FormattedMessage } from "react-intl";
import { LOGIN } from "./api/auth";
import { useMutation } from "@apollo/client";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type ParamTypes = {
  company: string;
  key: string;
};

type CustomProps = {
  directLogin: boolean;
};

type LoginScreenProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  CustomProps;

function LoginScreen(props: LoginScreenProps) {
  let query = useQuery();
  let { key, company } = useParams<ParamTypes>();
  const { saveAccessToken, isAuthenticated, directLogin } = props;
  let history = useHistory();
  const { addToast } = useToasts();
  const [gqlLogin, { data }] = useMutation(LOGIN);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (directLogin) {
      const email = query.get("email");
      gqlLogin({
        variables: { email, key, company, password: "", name: "" },
      });
    }
  }, []);

  useEffect(() => {
    if (data && data.login) {
      saveAccessToken(data.login.accessToken);
    } else if (data && data.login === null) {
      addToast(
        <FormattedMessage
          id="wrongUsernameOrPassword"
          defaultMessage="Väärä käyttäjänimi tai salasana"
        />,
        {
          appearance: "error",
        }
      );
    }
  }, [data, saveAccessToken, addToast]);

  const handleUsernameChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(e.target.value);
  };

  const signIn = () => {
    gqlLogin({
      variables: {
        email: username,
        password,
        key: "",
        company: "",
        name: "",
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/browse");
    }
  }, [isAuthenticated, history, addToast]);

  return (
    <Grid className="LoginScreen">
      <form className="LoginForm">
        <img className="Logo" src={logo} alt="logo" />
        <h2 style={{ marginTop: "0px" }}>Sign in</h2>
        <Grid
          className="InputContainer"
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <TextField
              id="username"
              key="username"
              name="username"
              onChange={handleUsernameChange}
              type="text"
              label="Username"
              className="LoginInput"
              variant="outlined"
              size="small"
              color="primary"
            />
          </Grid>
          <Grid item>
            <TextField
              id="password"
              key="password"
              name="password"
              onChange={handlePasswordChange}
              type="password"
              label="Password"
              className="LoginInput"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item>
            <Button
              id="login-button"
              variant="contained"
              color="primary"
              onClick={signIn}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      saveAccessToken: actions.saveAccessToken,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.appState.isAuthenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
