import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DayRange } from "react-modern-calendar-datepicker";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import ReservationCalendar from "./ReservationCalendar";
import CabinInformation from "./CabinInformation";
import Equipment from "./Equipment";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Cabin } from "./models/cabinModels";
import { useQuery } from "@apollo/client";
import { CABIN_INFO_QUERY } from "./api/cabins";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import {
  getStartDateFromDayRange,
  getEndDateFromDayRange,
  getLanguageId,
} from "./utils/commonUtils";
import { RootState } from "./redux/reducers";
import DynamicPrice from "./DynamicPrice";
import Distances from "./Distances";
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

function generateMonths(currentMonth: number) {
  let index = currentMonth;
  let queryMonths = [months[index]];

  do {
    index = index + 1;
    queryMonths.push(months[index]);
  } while (index < 12);

  return queryMonths;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  cabinCard: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px auto",
    "& .MuiCard-root": {
      width: "100%",
    },
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  bolded: {
    fontWeight: "bold",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  imageContainer: {
    WebkitBoxShadow: "0px 0px 15px 0px rgb(23 9 9 / 50%)",
    MozBoxShadow: "0px 0px 15px 0px rgb(23 9 9 / 50%)",
    boxShadow: "0px 0px 15px 0px rgb(23 9 9 / 50%))",
    "& .carousel-root": {
      display: "flex",
      flexDirection: "column",
    },
    "& .carousel-slider, & .slide img": {
      height: "250px",
    },
    "& .thumbs-wrapper": {
      margin: 0,
      height: 60,
      "& ul": {
        margin: 0,
        padding: 0,
      },
    },
    "& .thumb": {
      margin: 0,
      borderWidth: "1px !important",
      padding: 0,
      height: 60,
    },
    "& img": {
      height: "100%",
      objectFit: "cover",
    },
  },
  cabinInfo: {
    height: "100%",
    padding: 16,
    "& > .MuiGrid-container": {
      height: "calc(100% + 24px)",
    },
  },
  ratingIcon: {
    color: "#FFB400",
  },
  tab: {
    minWidth: "115px",
  },
  cardContentContainer: {
    padding: "16px 0",
  },
  flex: { flex: 1 },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

type CabinCardProps = CardProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface CardProps {
  cabin: Cabin;
  index: number;
  width?: any;
}

function CabinCard(props: CabinCardProps) {
  const [expanded, setExpanded] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [reservationDisabled, setReservationDisabled] = React.useState(true);
  const [customDateRange, setCustomDateRange] = React.useState<DayRange>({
    from: null,
    to: null,
  });
  const theme = useTheme();
  const horizontalCard = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  let history = useHistory();
  const {
    cabin,
    index,
    storeCabinForReservation,
    language,
    decodedAccessToken,
  } = props;
  const { loading, error, data } = useQuery(CABIN_INFO_QUERY, {
    variables: {
      cabinId: cabin.id,
      months: generateMonths(new Date().getMonth()),
      langId: getLanguageId(language),
      ownerNumber: parseInt(decodedAccessToken?.omnro),
    },
    fetchPolicy: "no-cache",
  });

  const renderRating = (rating: number) => {
    let ratings = [];

    for (let i = 0; i < rating; i++) {
      let icon = (
        <StarIcon key={`rating_${i}`} className={classes.ratingIcon} />
      );
      ratings.push(icon);
    }

    if (rating % 1 !== 0) {
      ratings.push(
        <StarHalfIcon key={`rating_half`} className={classes.ratingIcon} />
      );
    }

    return ratings;
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleReservation = () => {
    storeCabinForReservation(cabin);
    history.push(`/reservation/${cabin.id}`);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const secondaryImages: any = cabin.secondaryPictureUrls
    ? cabin.secondaryPictureUrls.map((url: string, index: number) => {
        return (
          <div key={`image_${index}`}>
            <img src={url} alt="notfound" />
          </div>
        );
      })
    : [];

  return (
    <div className={classes.cabinCard} key={`cabin_${index}`}>
      <Card>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.imageContainer}>
            <Carousel showThumbs={horizontalCard} showArrows={true}>
              <div>
                <img src={cabin.mainPictureUrl} alt="notFound" />
              </div>
              {secondaryImages}
            </Carousel>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardContent className={classes.cabinInfo}>
              <Grid container direction="column" wrap="nowrap" spacing={3}>
                <Grid item>
                  <Grid container direction="row" justify="space-between">
                    <Grid item className={classes.bolded}>
                      {cabin.name}
                    </Grid>
                    <Grid item>
                      {cabin.numberOfBeds} + {cabin.numberOfExtraBeds}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justify="space-between">
                    {customDateRange.from && customDateRange.to ? (
                      <DynamicPrice
                        startDate={getStartDateFromDayRange(customDateRange)}
                        endDate={getEndDateFromDayRange(customDateRange)}
                        cabin={cabin}
                      />
                    ) : (
                      <Grid item>{cabin.price}€</Grid>
                    )}
                    <Grid item>{renderRating(cabin.rating)}</Grid>
                  </Grid>
                </Grid>

                <span className={classes.flex} />

                {!expanded && (
                  <Grid item container direction="row" justify="space-between">
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleExpandClick}
                      >
                        <FormattedMessage
                          id="cabinInfo"
                          defaultMessage="Lisätiedot"
                        />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleExpandClick}
                      >
                        <FormattedMessage
                          id="makeReservation"
                          defaultMessage="Varaa"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Grid>
        </Grid>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.cardContentContainer}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant={
                isWidthDown("sm", props.width) ? "scrollable" : "fullWidth"
              }
              indicatorColor="primary"
              textColor="primary"
              centered={isWidthDown("sm", props.width) ? false : true}
            >
              <Tab
                label={
                  <FormattedMessage
                    id="reservations"
                    defaultMessage="Varaukset"
                  />
                }
                {...a11yProps(0)}
                classes={{ root: classes.tab }}
              />
              <Tab
                label={
                  <FormattedMessage id="information" defaultMessage="Tiedot" />
                }
                {...a11yProps(1)}
                classes={{ root: classes.tab }}
              />
              <Tab
                label={
                  <FormattedMessage id="equipment" defaultMessage="Varustelu" />
                }
                {...a11yProps(2)}
                classes={{ root: classes.tab }}
              />
              {/* <Tab
                label={
                  <FormattedMessage
                    id="environment"
                    defaultMessage="Ympäristö"
                  />
                }
                {...a11yProps(3)}
                classes={{ root: classes.tab }}
              /> */}
              <Tab
                label={
                  <FormattedMessage
                    id="distances"
                    defaultMessage="Etäisyydet"
                  />
                }
                {...a11yProps(4)}
                classes={{ root: classes.tab }}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <ReservationCalendar
                cabin={cabin}
                data={data}
                loading={loading}
                setReservationDisabled={setReservationDisabled}
                setCustomDateRange={setCustomDateRange}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <CabinInformation
                cabinInformation={data?.cabinInformation}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Equipment
                cabinInformation={data?.cabinInformation}
                loading={loading}
              />
            </TabPanel>
            {/*             <TabPanel value={tabValue} index={3}>
              Ympäristö
            </TabPanel> */}
            <TabPanel value={tabValue} index={3}>
              <Distances
                cabinInformation={data?.cabinInformation}
                loading={loading}
              />
            </TabPanel>
            {expanded && (
              <CardActions disableSpacing>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleExpandClick}
                    >
                      <FormattedMessage
                        id="closeExpanded"
                        defaultMessage="Sulje"
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={expanded ? handleReservation : handleExpandClick}
                      disabled={reservationDisabled}
                    >
                      <FormattedMessage
                        id="makeReservation"
                        defaultMessage="Varaa"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            )}
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      storeCabinForReservation: actions.storeCabinForReservation,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  language: state.appState.language,
  decodedAccessToken: state.appState.decodedAccessToken,
});

export default withWidth()(
  connect(mapStateToProps, mapDispatchToProps)(CabinCard)
);
