import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  Calendar,
  Day,
  DayRange,
  utils,
} from "react-modern-calendar-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import { Booking, Cabin, Day as CabinDay } from "./models/cabinModels";
import { CustomDate, generateUnavailableDates } from "./utils/calendarUtils";
import { Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useToasts } from "react-toast-notifications";
import { RootState } from "./redux/reducers";
import { getMonthFromString } from "./utils/calendarUtils";
import dayjs from "dayjs";

function getStartDate(bookings: Booking[], dayRange: DayRange) {
  const month = bookings.find((booking: Booking) => {
    return (
      booking.year === dayRange?.from?.year &&
      getMonthFromString(booking.month) === dayRange.from.month
    );
  });

  if (month) {
    const day = month.days.find((day: CabinDay) => {
      return day.day === dayRange?.from?.day;
    });

    return day;
  }
}

const finnishMonths = [
  "Tammikuu",
  "Helmikuu",
  "Maaliskuu",
  "Huhtikuu",
  "Toukokuu",
  "Kesäkuu",
  "Heinäkuu",
  "Elokuu",
  "Syyskuu",
  "Lokakuu",
  "Marraskuu",
  "Joulukuu",
];

const englishMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const finnishDays = [
  {
    name: "Maanantai",
    short: "Ma",
  },
  {
    name: "Tiistai",
    short: "Ti",
  },
  {
    name: "Keskiviikko",
    short: "Ke",
  },
  {
    name: "Torstai",
    short: "To",
  },
  {
    name: "Perjantai",
    short: "Pe",
  },
  {
    name: "Lauantai",
    short: "La",
    isWeekend: true,
  },
  {
    name: "Sunnuntai", // used for accessibility
    short: "Su", // displayed at the top of days' rows
    isWeekend: true, // is it a formal weekend or not?
  },
];

const englishDays = [
  {
    name: "Monday",
    short: "Mon",
  },
  {
    name: "Tuesday",
    short: "Tue",
  },
  {
    name: "Wednesday",
    short: "Wed",
  },
  {
    name: "Thursday",
    short: "Thu",
  },
  {
    name: "Friday",
    short: "Fri",
  },
  {
    name: "Saturday",
    short: "Sat",
    isWeekend: true,
  },
  {
    name: "Sunday", // used for accessibility
    short: "Sun", // displayed at the top of days' rows
    isWeekend: true, // is it a formal weekend or not?
  },
];

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px auto",
    "& .customCalendarClass": {
      "& .Calendar__sectionWrapper": {
        minHeight: "21em",
      },
    },
    "& .reserved:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected)": {
      backgroundColor: "#ff7379 !important",
      color: "#000000 !important",
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "#ff7379 !important",
        color: "#000000 !important",
        borderRadius: "0px",
      },
    },
    // For starting day day half green square when it's selected
    "& .-selectedStart:not(.-selected)": {
      background: "linear-gradient(to bottom right,#ffffff 50%,#76ff8c 50%)",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "1px",
      borderBottom: "1px",
      borderRadius: "0px",
      color: "#000000",
      "&:hover": {
        background: "linear-gradient(to bottom right,#ffffff 50%,#76ff8c 50%)",
        border: "none 1px 1px none",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "1px",
        borderBottom: "1px",
        borderRadius: "0px",
        color: "#000000",
      },
    },
    // For ending day day half green square when it's selected
    "& .-selectedEnd:not(.-selected)": {
      background: "linear-gradient(to bottom right,#76ff8c 50%,#ffffff 50%)",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "1px",
      borderBottom: "1px",
      borderRadius: "0px",
      color: "#000000",
      "&:hover": {
        background: "linear-gradient(to bottom right,#76ff8c 50%,#ffffff 50%)",
        border: "none 1px 1px none",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "1px",
        borderBottom: "1px",
        borderRadius: "0px",
        color: "#000000",
      },
    },
    // For ending day half red square when it's not selected
    "& .endingDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected)": {
      background: "linear-gradient(to bottom right,#ffffff 50%,#ff7379 50%)",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "1px",
      borderBottom: "1px",
      borderRadius: "0px",
      "&:hover": {
        background: "linear-gradient(to bottom right,#ffffff 50%,#ff7379 50%)",
        border: "none 1px 1px none",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "1px",
        borderBottom: "1px",
        borderRadius: "0px",
      },
    },
    // For ending day half red square when it's selected
    "& .endingDay:not(.-selectedStart):not(.-selectedBetween):not(.-selected)": {
      background: "linear-gradient(to bottom right,#76ff8c 50%,#ff7379 50%)",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "1px",
      borderBottom: "1px",
      borderRadius: "0px",
      color: "#000000",
      "&:hover": {
        background: "linear-gradient(to bottom right,#76ff8c 50%,#ff7379 50%)",
        border: "none 1px 1px none",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "1px",
        borderBottom: "1px",
        borderRadius: "0px",
      },
    },
    // For starting day day half red square when it's not selected
    "& .startingDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected)": {
      background: "linear-gradient(to bottom right, #ff7379 50%, #ffffff 50%)",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "1px",
      borderBottom: "1px",
      borderRadius: "0px",
      color: "#000000",
      "&:hover": {
        background:
          "linear-gradient(to bottom right, #ff7379 50%, #ffffff 50%)",
        border: "none 1px 1px none",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "1px",
        borderBottom: "1px",
        borderRadius: "0px",
      },
    },
    // For starting day day half red square when it is selected
    "& .startingDay.-selectedStart:not(.-selected)": {
      background: "linear-gradient(to bottom right, #ff7379 50%, #76ff8c 50%)",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "1px",
      borderBottom: "1px",
      borderRadius: "0px",
      color: "#000000",
      "&:hover": {
        background:
          "linear-gradient(to bottom right, #ff7379 50%, #76ff8c 50%)",
        border: "none 1px 1px none",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "1px",
        borderBottom: "1px",
        borderRadius: "0px",
      },
    },
  },
  datePickerNote: {
    fontSize: "12px",
    color: "red",
    margin: "0 0 20px 0",
  },
  datePickerTimeInfo: {
    fontSize: "12px",
    color: "black",
  },
  time: {
    fontWeight: "bold",
  },
}));

function getMonths(lang: string) {
  switch (lang) {
    case "fi":
      return finnishMonths;
    case "en":
      return englishMonths;
    default:
      return finnishMonths;
  }
}

function getDays(lang: string) {
  switch (lang) {
    case "fi":
      return finnishDays;
    case "en":
      return englishDays;
    default:
      return finnishDays;
  }
}

interface DateRangeRules {
  min?: number;
  max?: number;
}

interface CustomProps {
  cabin: Cabin;
  loading: boolean;
  data: any;
  setReservationDisabled: (state: boolean) => void;
  setCustomDateRange: (dateRange: DayRange) => void;
}

type ReservationCalendarProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  CustomProps;

function ReservationCalendar(props: ReservationCalendarProps) {
  const classes = useStyles();
  const { addToast } = useToasts();
  const {
    changeDateRange,
    loading,
    data,
    language,
    setReservationDisabled,
    activeFilters,
    setCustomDateRange,
    cabin,
  } = props;

  let defaultFrom = null;
  let defaultTo = null;

  if (activeFilters && activeFilters.onlyAvailable) {
    try {
      const startArray = activeFilters.startDate.split("-");
      defaultFrom = {
        year: parseInt(startArray[0], 10),
        month: parseInt(startArray[1], 10),
        day: parseInt(startArray[2], 10),
      };

      const endArray = activeFilters.endDate.split("-");
      defaultTo = {
        year: parseInt(endArray[0], 10),
        month: parseInt(endArray[1], 10),
        day: parseInt(endArray[2], 10),
      };
    } catch {
      console.log("Cannot parse filter days to calendar range.");
    }
  }

  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
    from: defaultFrom,
    to: defaultTo,
  });

  const [maxDateRange, setMaxDateRange] = useState<number>();
  const [minDateRange, setMinDateRange] = useState<number>();

  const myCustomLocale = {
    // months list by order
    months: getMonths(language),

    // week days by order
    weekDays: getDays(language),

    // just play around with this number between 0 and 6
    weekStartingIndex: 6,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject: any) {
      return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date: any) {
      return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date: any) {
      return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit: any) {
      return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",

    // for input range value
    from: "from",
    to: "to",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
  };

  let unavailableDates: CustomDate[] = [];
  let possibleEndingDays: CustomDate[] = [];
  let possibleStartingDays: CustomDate[] = [];
  let raffleDays: CustomDate[] = [];

  const bookingsCalendar: Booking[] = data?.cabinInformation?.bookingsCalendar;

  if (
    bookingsCalendar &&
    Array.isArray(bookingsCalendar) &&
    bookingsCalendar.length > 0
  ) {
    const generatedCustomDates = generateUnavailableDates(bookingsCalendar);
    unavailableDates = generatedCustomDates.unavailableDates;
    possibleEndingDays = generatedCustomDates.possibleEndingDays;
    possibleStartingDays = generatedCustomDates.possibleStartingDays;
    raffleDays = generatedCustomDates.raffleDays;
  }

  const callBackChange = useCallback(
    (range: DayRange) => {
      changeDateRange(range);
      setCustomDateRange(range);
    },
    [changeDateRange, setCustomDateRange]
  );

  useEffect(() => {
    if (selectedDayRange.from && selectedDayRange.to) {
      const fromDate = new Date(
        selectedDayRange.from?.year,
        selectedDayRange.from?.month - 1,
        selectedDayRange.from?.day
      );

      const toDate = new Date(
        selectedDayRange.to?.year,
        selectedDayRange.to?.month - 1,
        selectedDayRange.to?.day
      );

      const bookingStartDate =
        bookingsCalendar && getStartDate(bookingsCalendar, selectedDayRange);
      const diff = dayjs(toDate).diff(fromDate, "day");

      if (
        bookingStartDate?.minReservationPeriod &&
        bookingStartDate.minReservationPeriod > diff
      ) {
        // selected range is too small
        setMinDateRange(bookingStartDate.minReservationPeriod);
      } else {
        setMinDateRange(undefined);
      }

      if (
        bookingStartDate?.maxReservationPeriod &&
        bookingStartDate.maxReservationPeriod < diff
      ) {
        // selected range is too big
        setMaxDateRange(bookingStartDate.maxReservationPeriod);
      } else {
        setMaxDateRange(undefined);
      }
    } else {
      setReservationDisabled(true);
    }
    callBackChange(selectedDayRange);
  }, [
    selectedDayRange,
    callBackChange,
    bookingsCalendar,
    setReservationDisabled,
  ]);

  // if startdate and enddate have been selected and
  useEffect(() => {
    if (
      !minDateRange &&
      !maxDateRange &&
      selectedDayRange.from &&
      selectedDayRange.to
    ) {
      setReservationDisabled(false);
    } else {
      setReservationDisabled(true);
    }
  }, [setReservationDisabled, minDateRange, maxDateRange, selectedDayRange]);

  const handleDisabledSelect = (disabledDay: Day) => {
    addToast(
      <FormattedMessage
        id="incorrectDaySelectionWarning"
        defaultMessage="Kokonaan punaisella merkatut päivät ovat jo varattuja."
      />,
      {
        appearance: "error",
      }
    );
  };

  return (
    <div className={classes.calendarContainer}>
      {loading ? (
        <Grid>
          <FormattedMessage
            id="loadingReservationCalendar"
            defaultMessage="Ladataan varauskalenteria..."
          />
        </Grid>
      ) : (
        <Calendar
          value={selectedDayRange}
          onChange={setSelectedDayRange}
          shouldHighlightWeekends
          locale={myCustomLocale}
          customDaysClassName={unavailableDates
            .concat(possibleEndingDays)
            .concat(possibleStartingDays)
            .concat(raffleDays)}
          disabledDays={unavailableDates}
          onDisabledDayError={handleDisabledSelect}
          minimumDate={utils("en").getToday()}
          calendarClassName="customCalendarClass"
          renderFooter={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "1rem 2rem",
                fontSize: "12px",
                flexDirection: "column",
              }}
            >
              {minDateRange && (
                <Typography className={classes.datePickerNote}>
                  <FormattedMessage
                    id="invalidDateRange"
                    defaultMessage="Vuokrausjakson on oltava vähintään {minDays} päivää"
                    values={{ minDays: minDateRange }}
                  />
                </Typography>
              )}
              {maxDateRange && (
                <Typography className={classes.datePickerNote}>
                  <FormattedMessage
                    id="invalidDateRange"
                    defaultMessage="Vuokrausjakso tälle aloituspäivälle saa olla maksimissaan {maxDays} päivää"
                    values={{ maxDays: maxDateRange }}
                  />
                </Typography>
              )}

              {cabin.reservationStartTime && (
                <Typography className={classes.datePickerTimeInfo}>
                  <FormattedMessage
                    id="invalidDateRange"
                    defaultMessage="Varauksen alkamisaika "
                  />
                  <span className={classes.time}>
                    {cabin.reservationStartTime.replace(new RegExp(":00$"), "")}
                  </span>
                </Typography>
              )}
              {cabin.reservationEndTime && (
                <Typography className={classes.datePickerTimeInfo}>
                  <FormattedMessage
                    id="invalidDateRange"
                    defaultMessage="Varauksen päättymisaika "
                  />
                  <span className={classes.time}>
                    {cabin.reservationEndTime.replace(new RegExp(":00$"), "")}
                  </span>
                </Typography>
              )}
            </div>
          )}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  language: state.appState.language,
  activeFilters: state.appState.activeFilters,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeDateRange: actions.handleDateRangeChange,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationCalendar);
